import React, { useState, useEffect } from 'react';
import './sevenchecker.styles.css';

const SevenChecker = () => {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  useEffect(() => {
    // Initialize the audio player
    const player = new Audio(process.env.PUBLIC_URL + '/bole_jo_koyal.mp3');
    setAudioPlayer(player);
    player.load();

    // Cleanup the audio player on component unmount
    return () => {
      if (player) {
        player.pause();
        player.currentTime = 0;
      }
    };
  }, []);

  const checkInput = (input) => {
    if (!isNaN(input)) {
      const sum = input
        .toString()
        .split('')
        .reduce((acc, digit) => acc + parseInt(digit), 0);
      if (sum === 7) {
        setResult(`${input} has digits ${input.split('').join('+')} Thala for a reason!`);
        playAudio();
      } else {
        setResult('');
        pauseAudio();
      }
    } else if (typeof input === 'string') {
      if (input.length === 7) {
        setResult(`${input} has ${input.length} letters (${input.split('').join(',')}) Thala for a reason!`);
        playAudio();
      } else {
        setResult('');
        pauseAudio();
      }
    } else {
      setResult('');
      pauseAudio();
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
    checkInput(event.target.value);
  };

  const playAudio = () => {
    if (audioPlayer) {
      audioPlayer.play().then(() => {
        setIsMusicPlaying(true);
      }).catch((error) => {
        console.error('Audio play failed:', error);
        // You can add fallback behavior here (like showing an error message)
      });
    }
  };

  const pauseAudio = () => {
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0; // Reset the audio to the beginning
      setIsMusicPlaying(false);
    }
  };

  return (
   
        <div className={`sevenchecker-container ${isMusicPlaying ? 'music-playing' : ''}`}>
      <div className="input-container">
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          className="input-field"
          placeholder="Enter a number or text"
        />
      </div>

      <p className={`result ${result.includes('Thala') ? 'thala' : ''}`}>
        {result}
      </p>
    </div>

  );
};

export default SevenChecker;