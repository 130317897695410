import React, { useEffect } from 'react';

import "./resume.styles.css";
import Footer from "../../components/footer/footer.component";

const AutoDownload = () => {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/resume.pdf`;
        link.download = 'pankajvyasresume.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    return (
      <div className="section">
        <div className="section resume-box">
            <h1 className="download-title">Thanks for downloading the resume...</h1>
            <p className="download-link">If it doesn't, click <a href={`${process.env.PUBLIC_URL}/resume.pdf`} download>here</a>.</p>
        </div>
        <Footer/>
      </div>
    );
};

export default AutoDownload;
