import {
  faComment,
  faEnvelope,
  faImage,
  faMapMarkerAlt,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import resumeImage from "../../assets/img/pankaj-pv-1.jpg";
import Footer from "../../components/footer/footer.component";
import Notebook from "../../components/notebook/notebook.component";
import Navbar from "../../components/navbar/navbar.component";

import "./contact.styles.css";

const Contact = () => {


  const contactStyle = {
    background: `linear-gradient(rgba(25, 1, 1, 0.6), rgba(6, 0, 0, 0.6)),
        url("https://github.com/pvyas033/host/blob/master/img/img-2.jpg?raw=true") center/cover no-repeat`,
  };

  return (
    <div>
      <Navbar/>
      <section className="contact section" id="contact"> 
        <div className="contact-body">
          <div className="container">
            <div className="header">
              <h3 className="title" data-title="Let's connect">
                Contact
              </h3>
            </div>
            <div className="section">
              <div className="contact-info">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
                <div className="email">
                  <h1>pankajvyas033@gmail.com</h1>
                </div>
              </div>
              <div className="contact-info">
                <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                <div className="email">
                  <h1>Bangalore, India</h1>
                </div>
              </div>
            </div>
            <div className="contact-button-panel">
              <a
                href="https://t.me/pankajvyas033"
                className="btn drop-message"
                target="_blank"
                rel="noreferrer"
              >
                Drop a message {"      "}
                <FontAwesomeIcon icon={faComment} size="1x" />
              </a>
            </div>
          </div>
          <div className="notebook-add">
            <Notebook />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
