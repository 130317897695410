import { Route, Switch } from "react-router";

import About from "./pages/about/about.component";
import Blogs from "./pages/blogs/blogs.component";
import Contact from "./pages/contact/contact.component";
import Home from "./pages/home/home.component";
import Work from "./pages/work/work.component";
import AutoDownload from "./pages/resume/resume.component";
import LRModelPY from "./components/mlfw/linear.regression/lrmodelpy.component";

import "./App.css";
import NotFound from "./pages/notfound/notfound.component";
import LRModelJDK from "./components/mlfw/linear.regression/lrmodel.component";
import SevenChecker from "./components/sevenchecker/sevenchecker.component";
import Navbar from "./components/navbar/navbar.component";
import Footer from "./components/footer/footer.component";

function App() {

  const appStyle = {
    background: `linear-gradient(rgba(25, 1, 1, 0.7), rgba(6, 0, 0, 0.8)),
        url("https://github.com/pvyas033/host/blob/master/img/img-2.jpg?raw=true") center/cover no-repeat`,
  };

  return (
    <div className="app" style={appStyle}>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/projects" component={Work}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/blogs" component={Blogs}></Route>
        <Route path="/download" component={AutoDownload}></Route>
        <Route path="/ml/lrmodel" component={LRModelPY}></Route>
        <Route path="/ml/lrmodelj" component={LRModelJDK}></Route>
        <Route path="/sevenchecker" component={SevenChecker}></Route>
        <Route path="*" component={NotFound}/>
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
