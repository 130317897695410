import React from "react";

import Footer from "../../components/footer/footer.component";
import Navbar from "../../components/navbar/navbar.component";
import ProjectList from "../../components/projectlist/projectlist.component.jsx";

import "./work.styles.css";

const Work = () => {

  const workStyle = {
    background: `linear-gradient(rgba(25, 1, 1, 0.6), rgba(6, 0, 0, 0.6)),
        url("https://github.com/pvyas033/host/blob/master/img/img-2.jpg?raw=true") center/cover no-repeat`,
  };

  return (
    <div>
    <section id="portfolio">
      <Navbar/>
      <ProjectList/>
      <div className="more-folio">
          <a
            href="https://github.com/pvyas033"
            target="_blank"
            rel="noreferrer"
            className="btn github-repo-btn"
          >
            GitHub Repo
          </a>
        </div>
        
    </section>
    </div>
  );
};

export default Work;
