import React from "react";

import "./projectlist.styles.css";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectProjects } from "../../redux/work/work.selectors";

import Project from "../../components/project/project.component";

const ProjectList = ({ projects }) => {

  return (
    <div className="projects-section">
        <div className="projects">
          {projects.map(({ id, ...otherProperties }) => (
            <Project key={id} {...otherProperties} />
          ))}
        </div>
    </div>
  );
};

// state is root reducer
const mapStateToProps = createStructuredSelector({
    projects: selectProjects,
});

export default connect(mapStateToProps)(ProjectList)
