import React from 'react';
import './lrmodel.styles.css';
import Footer from '../../footer/footer.component';

const python_name=">> Python";

const LRModelJDK = () => {
    return (
        <div className="section readme">
            <div className='modelcontainer'>
            <a href={`https://pankajvyas.in/#/ml/lrmodel`} className="navigate-btn">{python_name}</a>

            <h1>ML Framework</h1>
            <p>Welcome to the ML Framework! This framework can be use to train linear regression dataset. 
                Attached zip contains batch files to help you preprocess data, train models, and evaluate their performance. 
                Below is an explanation of each batch file and how to use them. This is Java framework so it is required to have JDK 17 installed in your machine.</p>

            <h2>Files</h2>
            <ol>
                <li><strong>encoder.bat</strong></li>
                <li><strong>splitter.bat</strong></li>
                <li><strong>scalerminmax.bat</strong></li>
                <li><strong>trainit.bat</strong></li>
                <li><strong>rescale.bat</strong></li>
                <li><strong>score.bat</strong></li>
                <li><strong>testit.bat</strong></li>
            </ol>

            <h2>Usage</h2>

            <h3>1. encoder.bat</h3>
            <p>This script encodes a dataset using one-hot encoding.</p>
            <p><strong>Parameters:</strong></p>
            <ul>
                <li><code>datasetFileName</code>: Name of the input dataset file.</li>
                <li><code>outputFileName</code>: Name of the output file you want to generate with encoded data.</li>
                <li><code>comma separated columns</code>: Columns to be encoded, separated by commas.</li>
                <li><code>hasHeader</code>: Indicate if the dataset contains a header (<code>yes</code> or <code>no</code>).</li>
            </ul>
            <p><strong>Example:</strong></p>
            <pre><code>encoder.bat dataset.csv encoded_dataset.csv "1,4,5" yes</code></pre>

            <h3>2. splitter.bat</h3>
            <p>This script splits the dataset into training and testing datasets.</p>
            <p><strong>Parameters:</strong></p>
            <ul>
                <li><code>datasetFileName</code>: Name of the input dataset file.</li>
                <li><code>trainingDatasetFileName</code>: Name of the output training dataset file you want to generate.</li>
                <li><code>testingDatasetFileName</code>: Name of the output testing dataset file you want to generate.</li>
                <li><code>minorPercentage</code>: Percentage of the dataset in the testing dataset (must be less than 50%).</li>
            </ul>
            <p><strong>Example:</strong></p>
            <pre><code>splitter.bat dataset.csv train.csv test.csv 30</code></pre>

            <h3>3. scalerminmax.bat</h3>
            <p>This script scales the dataset using Min-Max scaling.</p>
            <p><strong>Parameters:</strong></p>
            <ul>
                <li><code>inputFileName</code>: Name of the input dataset file.</li>
                <li><code>outputFileName</code>: Name of the output file where you want to store scaled values.</li>
                <li><code>startColumn</code>: The starting column for scaling.</li>
                <li><code>endColumn</code>: The ending column for scaling.</li>
                <li><code>minmaxFileName</code>: Name of the Min-Max file you want to generate or want to use.</li>
                <li><code>wantToScaleWithProvidedMinMax</code>: Indicate if you want to use the provided Min-Max file (<code>Y</code> or <code>N</code>).</li>
            </ul>
            <p><strong>Example:</strong></p>
            <p>If you want to generate minmax file for scaling give N</p>
            <pre><code>scalerminmax.bat dataset.csv scaled_dataset.csv 1 5 minmax.csv N</code></pre>
            <p>If you want to use provided minmax file for scaling give Y</p>
            <pre><code>scalerminmax.bat dataset.csv scaled_dataset.csv 1 5 minmax.csv Y</code></pre>

            <h3>4. trainit.bat</h3>
            <p>This script trains the model using the training dataset.</p>
            <p><strong>Parameters:</strong></p>
            <ul>
                <li><code>datasetFileName</code>: Name of the input training dataset file.</li>
                <li><code>learningRate</code>: Learning rate for the training (e.g. <code>0.1</code>, <code>0.01</code>, <code>0.001</code>).</li>
                <li><code>historySize</code>: Size of the history (rows) to be stored.</li>
                <li><code>historyFileName</code>: Name of the file to store the history.</li>
                <li><code>graphFileName</code>: Name of the file to store the training graph.</li>
                <li><code>parametersFileName</code>: Name of the file to store the model parameters.</li>
                <li><code>numberOfIterations</code>: Number of iterations (<code>optional</code>). If not provided, training continues until interrupted by the user when the user hits enter.</li>
            </ul>
            <p><strong>Example:</strong></p>
            <pre><code>trainit.bat train.csv 0.01 100 history.csv graph.csv parameters.csv 1000</code></pre>
            <p><strong>or</strong></p>
            <pre><code>trainit.bat train.csv 0.01 100 history.csv graph.csv parameters.csv</code></pre>

            <h3>5. testit.bat</h3>
            <p>This script tests the trained model using the testing dataset.</p>
            <p><strong>Parameters:</strong></p>
            <ul>
                <li><code>datasetFileName</code>: Name of the input testing dataset file.</li>
                <li><code>resultFileName</code>: Name of the file to store the test results.</li>
                <li><code>parameterFileName</code>: Name of the file with the trained model parameters.</li>
            </ul>
            <p><strong>Example:</strong></p>
            <pre><code>testit.bat test.csv results.csv parameters.csv</code></pre>

            <h3>6. rescale.bat</h3>
            <p>This script rescales the dataset using the provided Min-Max file.</p>
            <p><strong>Parameters:</strong></p>
            <ul>
                <li><code>inputFileName</code>: Name of the input dataset file.</li>
                <li><code>outputFileName</code>: Name of the output rescaled dataset file you want to generate.</li>
                <li><code>minmaxFileName</code>: Name of the Min-Max file you want to use for rescaling.</li>
            </ul>
            <p><strong>Example:</strong></p>
            <pre><code>rescale.bat dataset.csv rescaled_dataset.csv minmax.csv</code></pre>

            <h3>7. score.bat</h3>
            <p>This script calculates the score of the model using the R2 score method.</p>
            <p><strong>Parameters:</strong></p>
            <ul>
                <li><code>resultFileName</code>: Name of the result file.</li>
            </ul>
            <p><strong>Example:</strong></p>
            <pre><code>score.bat results.csv</code></pre>
            <h2>Download</h2>
            <p>
                <a href={`${process.env.PUBLIC_URL}/mlfw.zip`} className="download-btn" download="ml_framework.zip">Download MLFW.zip</a>
            </p>

        </div>
        </div>
    );
};

export default LRModelJDK;