import React from "react";

import "./footer.styles.css";

const Footer = () => {

  
  const footerStyle = {
    background: `linear-gradient(rgba(25, 1, 1, 0.8), rgba(6, 0, 0, 0)),
        url("https://github.com/pvyas033/host/blob/master/img/img-1.jpg?raw=true") center/cover no-repeat`,
  };

  return (
    <footer className="footer-section" style={footerStyle}>
      <div className="bottom-footer">
        
        <div className="work-together">
          <a href="mailto:pankajvyas033@gmail.com" className="btn chat-btn">
            Want to work together? Let's Chat!
          </a>
        </div>
        <div className="email-body">
          <p className="footer-text text-email">Copyright &copy; Pankaj Vyas 2024-28</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
