import React from "react";
import { SocialIcon } from "react-social-icons";

import "./project.styles.css";

const Project = ({ header, title, bgImgUrl, description, link, btnText }) => {
  const projectStyle = {
    background: `linear-gradient(rgba(25, 1, 1, 0.6), rgba(6, 0, 0, 0.6)),
        url(${bgImgUrl}) center/cover no-repeat`,
  };

  return (
    <div style={projectStyle} className="project">
      <div className="project-stack-header">
        <p className="project-stack">{header}</p>
        <SocialIcon
          url={link}
          bgColor="white"
          className="git-fork"
          target="_blank"
        />
      </div>
      <h1 className="project-title">{title}</h1>
      <div className="project-description">
        <p>{description[0]}</p>
        <p>{description[1]}</p>
      </div>
      <a href={link} className="btn view-site" target="_blank" rel="noreferrer">
        {" "}
        {btnText}
      </a>
    </div>
  );
};

export default Project;
